<template>
  <div class="users-connection-setting">
    <div class="header">
      <div class="style-title-setting-global">
        {{
          $i18n.locale === 'fr'
            ? 'Gestion des connexions des utilisateurs'
            : 'User Connections Management'
        }}
      </div>
    </div>
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div v-else class="content-tab-users-connection">
      <v-row>
        <v-col>
          <div class="last-updated">
            {{
              $i18n.locale === 'fr' ? 'Dernière mise à jour :' : 'Last update :'
            }}
            <span class="time">{{ getLastUpdatedTime }}</span>
          </div></v-col
        >

        <v-col cols="4">
          <!-- <div v-if="getUserLoading" class="color-crm font-sz-12 font-text">
          {{ $t('loading') }}
          </div>-->
        </v-col>
        <v-col class="flex center search-btn">
          <v-btn
            @click="refresh"
            :loading="loading"
            class="btn-filter mr-3"
            small
            dark
            color="#5c2dd3"
            title=""
          >
            <font-awesome-icon class="sz-icon-plus img-h-16" icon="sync-alt" />
          </v-btn>
          <v-text-field
            v-model="searchFilter"
            clearable
            :prepend-inner-icon="'mdi-magnify'"
            :label="$t('search')"
            outlined
            :placeholder="
              $i18n.locale === 'fr'
                ? 'Rechercher un utilisateur'
                : 'Search user'
            "
            :persistent-placeholder="true"
            dense
            hide-details
            color="#5C2DD3"
          ></v-text-field
        ></v-col>
      </v-row>
      <div class="table-users mt-2">
        <v-data-table
          v-model="selectedTable"
          :headers="$i18n.locale == 'fr' ? fields : fieldsEn"
          :items-per-page="items.length"
          :items="items"
          class="table-users"
          hide-default-footer
          :calculate-widths="false"
          :fixed-header="true"
          hide-action
          :no-data-text="
            getUserLoading
              ? $i18n.locale == 'fr'
                ? 'Chargement... Veuillez patienter'
                : 'Loading... Please wait'
              : $i18n.locale == 'fr'
              ? `Il n'y a aucune donnée à afficher dans ce tableau.`
              : 'There is no data to display in this table.'
          "
        >
          <!-- BODY TABLE -->

          <template v-slot:[`item.user_full_name`]="{ item }">
            <td class="custom-cell">
              <strong>{{ item.user_full_name }}</strong>
              <div>{{ item.user_role }}</div>
            </td>
          </template>
          <template v-slot:[`item.device`]="{ item }">
            <td class="custom-cell">
              <strong>{{ item.device }}</strong>
              <div
                v-if="item.is_current_device"
                class="current-device-tag mb-2 mt-1"
              >
                {{ $i18n.locale == 'fr' ? 'Cet appareil' : 'This device' }}
              </div>
            </td>
          </template>

          <template v-slot:[`item.location`]="{ item }">
            <td class="custom-cell">
              <strong>{{ item.location }}</strong>
              <div>{{ item.ip_address }}</div>
            </td>
          </template>
          <template v-slot:[`item.connection`]="{ item }">
            <td class="custom-cell">
              <font-awesome-icon
                v-if="item.is_logged"
                class="mr-1 img-h-14"
                :style="{ color: '#4CAF50' }"
                :icon="['fas', 'circle']"
              />
              <font-awesome-icon
                v-else
                class="mr-1 img-h-14"
                :style="{ color: '#4CAF50' }"
                :icon="['far', 'circle']"
              />
              <strong>{{ item.login_time | date }}</strong>
              <div>{{ item.login_time | timeFromNow }}</div>
            </td>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <td class="custom-cell">
              <div class="actions" v-if="item.is_logged">
                <v-btn
                  color="#ff0031"
                  dark
                  small
                  @click.prevent="openDisconnectModal(item)"
                >
                  {{ $i18n.locale == 'fr' ? 'Déconnecter' : 'Disconnect' }}
                </v-btn>
              </div>
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
    <!-- disconnect User Modal -->
    <v-dialog v-model="disconnectModal" max-width="500" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale == 'fr'
                ? 'Déconnecter cet utilisateur'
                : 'Disconnect this user'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('disconnectModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p>
            {{
              $i18n.locale == 'fr'
                ? "Êtes-vous certain de déconnecter l'utilisateur"
                : 'Are you sure you want to disconnect the user'
            }}
            <strong class="name">
              {{
                selectedConnection ? selectedConnection.user_full_name : '--'
              }}
            </strong>
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getUserLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getUsersConnectionsError" class="error-msg">
              <ul v-if="Array.isArray(getUsersConnectionsError)">
                <li v-for="(e, index) in getUsersConnectionsError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getUsersConnectionsError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleDisconnect"
            :loading="getUserLoading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('disconnectModal')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END disconnect User Modal -->
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import * as moment from 'moment'
moment.locale('fr')
export default {
  data() {
    return {
      disconnectModal: false,
      selectedTable: [],
      selectedConnection: null,
      searchFilter: '',
      fields: [
        { value: 'user_full_name', text: 'Utilisateur', sortable: true },
        { value: 'device', text: 'Appareil', sortable: false },
        { value: 'location', text: 'Localisation', sortable: false },
        { value: 'connection', text: 'Connexion', sortable: false },
        { value: 'actions', text: 'Actions', sortable: false }
      ],
      fieldsEn: [
        { value: 'user_full_name', text: 'User', sortable: true },
        { value: 'device', text: 'Device', sortable: false },
        { value: 'location', text: 'Location', sortable: false },
        { value: 'connection', text: 'Connection', sortable: false },
        { value: 'actions', text: 'Actions', sortable: false }
      ],
      initLoading: true,
      loading: false
    }
  },
  methods: {
    ...mapActions(['fetchUsersConnections', 'disconnectUser']),
    openDisconnectModal(connection) {
      this.selectedConnection = connection
      this.disconnectModal = true
    },
    closeDialog(ref) {
      this[ref] = false
    },
    async handleDisconnect() {
      if (this.selectedConnection) {
        await this.disconnectUser(this.selectedConnection.id_connection)
        this.refresh()
        this.closeDialog('disconnectModal')
      }
    },
    itemsProviders() {
      let items = []
      items = this.getUsersConnections
      return items || []
    },
    removeDiacritics(str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
    normalizedContains(haystack, needle) {
      const regExp = new RegExp(this.removeDiacritics(needle), 'gi')
      return regExp.test(this.removeDiacritics(haystack))
    },
    refresh() {
      this.loading = true
      this.fetchUsersConnections()
      setTimeout(() => {
        this.loading = false
      }, 1000)
    }
  },
  computed: {
    ...mapGetters([
      'getUserLoading',
      'getUsersConnections',
      'getUsersConnectionsError',
      'getLastUpdatedTime'
    ]),

    items: function() {
      const search = this.searchFilter
        ? this.searchFilter.trim()
        : this.searchFilter
      if (!search) {
        return this.getUsersConnections
      }
      return this.getUsersConnections.filter(c =>
        this.normalizedContains(
          c.user_full_name.toLowerCase(),
          search.toLowerCase()
        )
      )
    }
  },
  async mounted() {
    await this.fetchUsersConnections()
    this.initLoading = false
  },
  filters: {
    date: function(value) {
      if (!value) return ''
      value = moment(value, 'YYYY-MM-DD HH:mm:ss').format(
        'DD MMM YYYY, HH:mm:ss'
      )
      return value
    },
    timeFromNow: function(value) {
      if (!value) return ''
      value = moment(value, 'YYYY-MM-DD HH:mm:ss').fromNow()
      return value
    }
  }
}
</script>

<style lang="scss" scoped>
.users-connection-setting {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .header-user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 11px;
  }
  .last-updated {
    font-size: 12px;
    color: #5c2dd3;
    font-style: italic;
    .time {
      text-transform: capitalize;
    }
  }
  .search-btn {
    .v-btn:not(.v-btn--round).v-size--small {
      height: 37px;
      min-width: 30px;
      margin-bottom: 2px;
      width: 36px;
    }
  }

  .input-search {
    border: 1px solid #dadada;
    outline: none;
    font-size: 16px;
    height: 40px;
    background: #fff;
    padding-left: 10px;
  }

  .searchIcon {
    padding: 10px;
    border: 1px solid #d6d8db;
    font-size: 16px;
    background: #d6d8da17;
  }

  .init-loading {
    padding: 5px 5px;
    width: 200px;
  }

  .content-tab-users-connection {
    padding-top: 4px;
    table {
      width: 100%;
      margin-bottom: 1rem;
      color: #212529;
      border: 0;
      border-top: 1px solid #dee2e6;
      border-bottom: 1px solid #b9babb;
      font-size: 12px;
      tr {
        td {
          .actions {
            margin-top: 4px;
            opacity: 0;
          }
        }
        &:hover {
          td {
            .actions {
              opacity: 1;
            }
          }
        }
      }

      .current-device-tag {
        font-weight: 700;
        text-align: center;
        width: 100px;
        font-size: 10px;
        background-color: #5c2dd34a;
        text-transform: uppercase;
        border-radius: 10px;
      }
    }
  }
}
</style>
<style lang="scss">
.users-connection-setting {
  .content-tab-users-connection {
    .table-users {
      table {
        border-collapse: collapse;
      }

      height: 100% !important;
      max-height: 100% !important;
      .header-users {
        th {
          text-align: left;
          padding: 0px 14px;
          height: 40px;
          font-size: 14px;
          white-space: nowrap;
          border-bottom: 1px solid #e0e0e0;
        }
      }
      thead {
        tr {
          th {
            white-space: nowrap;
            color: rgb(0 0 0) !important;
          }
        }
      }
      tbody {
        tr {
          td {
            font-size: 12px !important;
            letter-spacing: 0.17px;
            line-height: 17.16px;
            font-family: 'Roboto';
          }
        }
      }
      .v-data-table__wrapper::-webkit-scrollbar {
        width: 7px;
        height: 7px;
      }
      .v-data-table__wrapper::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #f0eef8;
        border-radius: 4px;
        background-color: rgba(238, 238, 238, 1) !important;
      }
      .v-data-table__wrapper::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(189, 189, 189, 1);
      }
      .v-data-table__wrapper {
        max-height: calc(100vh - 239px) !important;
        height: calc(100vh - 239px) !important;
        margin-bottom: 0;
        overflow-y: auto;
        overflow-x: auto;
        cursor: pointer;
      }
    }
  }
}
</style>
